<template>
  <!-- Área de Serviços/Sistemas da Intranet -->
  <div :class="{ 'dark-mode': isDarkMode }" class="grid">
    <div class="col-12 lg:col-6 xl:col-8" v-if="services.length > 0">
      <Card style="width: 100%; height: 100%">
        <template #subtitle>
          <div
            class="flex justify-content-between align-items-center flex-wrap"
          >
            <span class="custom-h5">Serviços</span>
            <span class="p-input-icon-left input-wrapper">
              <i class="pi pi-search" />
              <InputText
                v-model="servicesFilter"
                @input="filterServices"
                placeholder="Pesquisar..."
              />
            </span>
          </div>
        </template>
        <template #content>
          <div class="custom-scrollpanel ml-2">
            <div class="grid">
              <div
                class="col-6 sm:col-4 md:col-3 lg:col-3"
                v-for="service in filteredServices"
                :key="service.id"
              >
                <div
                  class="service-item"
                  :class="{ 'service-unavailable': !service.status }"
                  @click="verifyClick(service)"
                >
                  <div class="image-container">
                    <img
                      :src="$Storage.previewImg(service.identifierFile)"
                      alt="service image"
                    />
                    <span v-if="!service.status" class="unavailable-text"
                      >Indisponível</span
                    >
                  </div>
                  <div class="service-content">
                    <span class="text-900 service-title">{{
                      service.name
                    }}</span
                    ><br />
                    <span class="notification-meta">{{
                      service.category.description
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <!-- Área de Notificações do Sistema -->
    <div
      class="col-12 lg:col-6 xl:col-4 full-height-card"
      v-if="notifications.length > 0"
    >
      <Card>
        <template #subtitle>
          <div
            class="flex justify-content-between align-items-center flex-wrap"
          >
            <span class="custom-h5">Notificações</span>
            <span class="p-input-icon-left input-wrapper">
              <i class="pi pi-search" />
              <InputText
                v-model="notificationsFilter"
                @input="filterNotifications"
                placeholder="Pesquisar..."
              />
            </span>
          </div>
        </template>
        <template #content>
          <div class="custom-scrollpanel">
            <ul class="p-0 mx-0 mt-0 mb-4 list-none">
              <li
                v-for="notification in filteredNotifications"
                :key="notification.id"
                class="flex align-items-center py-2 border-bottom-1 surface-border mb-2"
                style="cursor: pointer"
                @click="showDialogNotification([notification])"
              >
                <div
                  :class="[
                    'custom-notification',
                    'type-' +
                      notification.notificationType.description.toLowerCase(),
                  ]"
                >
                  <font-awesome-icon
                    :icon="
                      getNotificationIcon(
                        notification.notificationType.description
                      )
                    "
                  />
                </div>
                <div class="notification-details">
                  <span class="text-900 line-height-3">{{
                    notification.title
                  }}</span>
                  <p class="notification-meta">
                    Publicado em:
                    {{ this.$DateTime.formatarDate(notification.dtCreated) }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </Card>
    </div>
    <!-- Área de Banner Informativo -->
    <div class="col-12">
      <Card style="max-height: 35vh; overflow: hidden">
        <template #subtitle
          ><span class="custom-h5">Acesso rápido</span></template
        >
        <template #content>
          <Carousel
            :value="banners"
            :numVisible="1"
            :numScroll="1"
            :autoplayInterval="3000"
          >
            <template #item="slotProps">
              <div class="relative mx-auto">
                <a :href="slotProps.data.linkUrl" target="_blank">
                  <img
                    class="flex items-center mb-1"
                    style="
                      border-radius: 20px;
                      width: 100%; /* Faz a imagem ocupar 100% da largura do contêiner */
                      height: auto; /* A altura será ajustada automaticamente */
                      max-width: 1350px; /* Limita a largura máxima a 1350px */
                      max-height: 200px; /* Limita a altura máxima a 200px */
                      object-fit: fill; /* Cobre todo o espaço disponível */
                    "
                    :src="$Storage.previewImg(slotProps.data.identifierFile)"
                  />
                </a>
              </div>
            </template>
          </Carousel>
        </template>
      </Card>
    </div>
  </div>
  <DialogNotification v-model:notificationSelected="notification" />
</template>

<script>
// Models
import UserAuth from "../../models/user_auth";

// Services
import ServiceService from "../../service/service/service";
import Token from "../../utilities/jwt/Token";
import BannerService from "../../service/banner/banner_service";
import NotificationService from "../../service/notifications/notifications_service";

//Componentes
import DialogNotification from "./components/DialogNotification.vue";

export default {
  components: {
    DialogNotification,
  },
  data() {
    return {
      userLogged: new UserAuth(),
      token: new Token(),
      serviceService: new ServiceService(),
      bannerService: new BannerService(),
      notificationsService: new NotificationService(),
      notification: null,
      banners: [],
      services: [],
      myServicesId: [],
      notifications: [],
      notificationNotExpired: [],
      servicesFilter: "",
      notificationsFilter: "",
      filteredServices: [],
      filteredNotifications: [],
    };
  },
  mounted() {
    this.getUserLogged();
    this.getServices();
    this.getBannersActives();
    this.getNotifications();
    // Verifica se a notificação não expirada já foi vista
    if (!sessionStorage.getItem("notificationNotExpiredView")) {
      this.getNotificationNotExpired();
    }
  },
  methods: {
    getUserLogged() {
      this.userLogged = this.$store.getters.getUserLogged;
      this.userLogged.services.forEach((s) => {
        this.myServicesId.push(s.service);
      });

      this.myServicesId = [...new Set(this.myServicesId)];
    },
    getServices() {
      this.serviceService.findAll().then((data) => {
        // Filtrar os serviços conforme suas categorias e permissões
        this.services = data.filter((service) => {
          if (!service.status) {
            return false; // Se o status for false, não incluir o serviço
          }

          // Se o serviço for "AO SERVIDOR", "INSTITUCIONAL" ou tiver id 1 ou 3
          if (
            service.category.description === "AO SERVIDOR" ||
            service.category.description === "INSTITUCIONAL" ||
            service.category.id === 1 ||
            service.category.id === 3
          ) {
            // Verificar se o serviço está na lista de permissões do usuário
            return this.myServicesId.includes(service.id);
          }

          // Caso contrário, exibe normalmente (mas sempre com status true)
          return true;
        });

        // Definir a lista de serviços filtrados inicialmente
        this.filteredServices = this.services;
      });
    },
    getBannersActives() {
      this.bannerService.findAllActives().then((data) => {
        this.banners = data;
      });
    },
    getNotifications() {
      this.notificationsService.findActives().then((data) => {
        this.notifications = data;
        this.filteredNotifications = data;
      });
    },
    getNotificationNotExpired() {
      this.notificationsService.findNotExpired().then((data) => {
        if (data.length > 0) { // SÓ EXIBE A MENSAGEM DE NOTIFICAÇÃO SE HOUVER RETORNO NO ARRAY DE NOTIFICAÇÕES
          this.notificationNotExpired = data;
          if (
            this.notificationNotExpired &&
            !sessionStorage.getItem("notificationNotExpiredView")
          ) {
            this.showDialogNotification(this.notificationNotExpired);
            sessionStorage.setItem("notificationNotExpiredView", "true");
          }
        }
      });
    },
    showDialogNotification(notification) {
      this.notification = notification;
      this.$store.state.views.notifications.dialogNotification = true;
    },
    getNotificationIcon(type) {
      // Função para retornar o ícone de acordo com o tipo de notificação
      switch (type.toLowerCase()) {
        case "importante":
          return ["fas", "triangle-exclamation"];
        case "manutencao":
          return ["fas", "person-digging"];
        case "noticia":
          return ["fas", "bell"];
        case "arquivo":
          return ["fas", "folder-plus"];
        default:
          return ["fas", "question-circle"]; // Ícone padrão caso o tipo não seja reconhecido
      }
    },
    //VERIFICA O ATO DE CLICAR NO ICONE DE SISTEMA PARA QUE, CASO UM SERVIÇO ESTEJA INDISPONIVEL, ELE NÃO POSSA REDIRECIONAR
    verifyClick(service) {
      if (!service.status) {
        this.$router.push("/not-found");
      } else {
        // Verifica se a categoria do serviço é igual a 2 ou 4, ou "AO CIDADÃO" ou "SERVIÇOS"
        if (
          service.category.id === 4 ||
          service.category.description === "SERVIÇOS" ||
          service.category.id === 2 ||
          service.category.description === "AO CIDADÃO"
        ) {
          // Abre o link em uma nova janela
          window.open(service.generalAccess, "_blank");
        } else {
          // Caso contrário, abre na mesma janela
          window.location.href = service.generalAccess;
        }
      }
    },
    //FILTRO DOS SERVIÇOS JA RENDERIZADOS
    filterServices() {
      this.filteredServices = this.services.filter((service) =>
        service.name.toLowerCase().includes(this.servicesFilter.toLowerCase())
      );
    },
    //FILTRO DAS NOTIFICACOES JÁ RENDERIZADAS
    filterNotifications() {
      this.filteredNotifications = this.notifications.filter((notification) =>
        notification.title
          .toLowerCase()
          .includes(this.notificationsFilter.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
@import url("../../assets/demo/custom_scrollpanel.scss");
@import url("../../assets/demo/custom_service_layout.scss");
@import url("../../assets/demo/custom_notification.scss");

:root {
  --font-size-scale: 1;
}

.custom-h5 {
  font-size: calc(
    1.5rem * var(--font-size-scale)
  ); /* Ajuste o 1.2rem conforme necessário */
}

.input-wrapper {
  max-width: 300px; /* Define uma largura máxima para evitar que o input fique muito grande */
}

.service-title {
  font-size: calc(
    1rem * var(--font-size-scale)
  ); /* Exemplo para os títulos dos serviços */
}

.notification-details {
  font-size: calc(
    0.9rem * var(--font-size-scale)
  ); /* Exemplo para as notificações */
}
</style>
