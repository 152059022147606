import axios from "axios";

import AuthService from "../auth/auth_service.js";

export default class BannerService extends AuthService {

  constructor() {
    super("banner");
  }

  updateStatus(banner) {
    return axios({
      method: "put",
      url: this.api_url + "banner/" + banner.id + "/update-status",
      withCredentials: true,
      data: !banner.status,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

  findAllActives() {
    return axios({
      method: "get",
      url: this.api_url + "banner/actives",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

}
